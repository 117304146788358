.clients-container {
  display: flex;
  margin: 1rem;

  .clients-list {
    width: 25%;
    height: 85vh;
    position: relative;
    cdk-virtual-scroll-viewport {
      height: 100%;
    }
    padding-right: 1rem;
  }

  app-scintillating-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .clients-content {
    width: 100%;
  }

  .clients-card-container {
    display: flex;
    margin: 10px 10px 10px 0px;
    width: 100%;
    .block-card {
      flex-direction: column;
      width: 100%;
      margin-right: 2rem;
      &:last-child {
        margin-right: 0;
      }
      .block-card-header {
        &:first-child {
          justify-content: space-between;
        }
      }
      .sub-title {
        display: flex;
        .material-icons-outlined {
          margin-right: 10px;
        }
      }
      .opportunity-container {
        height: 50vh;
        overflow-y: visible;
      }
    }
  }
}

.no-selection-div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  h2 {
    margin-bottom: 0.5rem;
  }
  mat-icon {
    transform: scale(1.8);
    margin-top: 1rem;
    color: darkgrey;
  }
}
