@use '@angular/material' as mat;

$dark-text: #000000;
$dark-primary-text: rgba($dark-text, 0.87);
$dark-accent-text: rgba($dark-primary-text, 0.54);

$primary-palette: (
  light: #eff0f3,
  main: #228cdb,
  dark: #a7d1f1,
  25: #f2faff,
  50: #e8f3fb,
  100: #d3e8f8,
  200: #a7d1f1,
  300: #7abae9,
  400: #4ea3e2,
  500: #228cdb,
  600: #1e7cc1,
  700: #1a6ba8,
  800: #165b8e,
  900: #165b8e,
  contrast: (
    lighter: $dark-primary-text,
    main: #fff,
    darker: #fff,
  ),
);

$accent-palette: (
  main: #63e776,
  light: #f7fef8,
  dark: #429b4f,
  50: #f7fef8,
  100: #effdf1,
  200: #e0fae4,
  300: #c1f5c8,
  400: #a1f1ad,
  500: #63e776,
  600: #58ce69,
  700: #4db45c,
  800: #429b4f,
  900: #378142,
  contrast: (
    lighter: $dark-accent-text,
    main: #fff,
    darker: #fff,
  ),
);

$warn-palette: (
  main: #ff0000,
  light: #ffb3b3,
  dark: #ff0000,
  contrast: (
    lighter: $dark-primary-text,
    main: #fff,
    darker: #fff,
  ),
);

$my-primary: mat.define-palette($primary-palette, main, light, dark);
$my-accent: mat.define-palette($accent-palette, main, light, dark);
$my-warn: mat.define-palette($warn-palette, main, light, dark);
$my-typography: mat.define-typography-config(
  $font-family: 'Manrope',
  $headline-1:
    mat.define-typography-level(112px, 112px, 400, $letter-spacing: -0.0134em),
  $headline-2:
    mat.define-typography-level(56px, 56px, 400, $letter-spacing: -0.0089em),
  $headline-3:
    mat.define-typography-level(45px, 48px, 400, $letter-spacing: 0em),
  $headline-4:
    mat.define-typography-level(34px, 40px, 400, $letter-spacing: 0.0074em),
  $subtitle-1:
    mat.define-typography-level(14px, 28px, 400, $letter-spacing: 0.0067em),
  $subtitle-2:
    mat.define-typography-level(14px, 28px, 800, $letter-spacing: 0.0094em),
  $body-1:
    mat.define-typography-level(16px, 20px, 400, $letter-spacing: 0.0179em),
  $body-2:
    mat.define-typography-level(14px, 20px, 400, $letter-spacing: 0.0179em),
  $button:
    mat.define-typography-level(14px, 14px, 400, $letter-spacing: 0.0179em),
  $caption:
    mat.define-typography-level(12px, 20px, 400, $letter-spacing: 0.0333em),
);

$my-theme: mat.define-light-theme(
  (
    color: (
      primary: $my-primary,
      accent: $my-accent,
      warn: $my-warn,
    ),
    typography: $my-typography,
    density: 0,
  )
);

@include mat.core();
@include mat.all-component-themes($my-theme);
@include mat.all-component-typographies($my-typography);

.mat-typography {
  p {
    margin: 0;
  }

  .mdc-text-field__input::-webkit-calendar-picker-indicator {
    display: block !important;
  }

  .mat-mdc-standard-chip .mdc-evolution-chip__cell--primary,
  .mat-mdc-standard-chip .mdc-evolution-chip__action--primary,
  .mat-mdc-standard-chip .mat-mdc-chip-action-label {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field
    .mdc-notched-outline__notch {
    border-right-style: hidden;
  }

  .mat-mdc-input-element {
    box-shadow: none !important;
  }

  .payment-status {
    .mat-mdc-form-field-subscript-wrapper {
      display: none;
    }
  }
}
