@use '@angular/material' as mat;

@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'styles/variables';
@import 'styles/core';
@import 'main-styles/main';

html,
body {
  height: 100%;
}
body {
  margin: 0;
}
