.title-box {
  display: flex;
  flex-direction: column;
}

.header-toolbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: transparent !important;
  box-shadow: none;
}

.page-header {
  font-size: 1.25rem;
  font-weight: bold;
}

.spacer {
  flex: 1 1 auto;
}

.search-input-input {
  margin-left: 8px;
  flex-grow: 1;
}

.button-group {
  display: flex;
  align-items: center;
  gap: 8px;
}
