.expert-underlay {
  width: 100%;
  height: 4.6875rem;
  margin-bottom: var(--spacing-s);
  display: flex;
  position: relative;
  box-shadow: var(--box-shadow-light);
  .expert-underlay-actions {
    justify-content: space-between;
    width: 8%;
    display: flex;
    height: 100%;
    align-items: center;
    padding: 0 1.25rem;
    background: linear-gradient(60deg, #5c4d79, #7fa2d8);
    .expert-underlay-action {
      transform: scale(0.877);
      color: white;
      cursor: pointer;
      &.no-cursor-events {
        opacity: 0.6;
        cursor: default;
        pointer-events: none;
      }
      a {
        color: white;
      }
    }
  }
  .expert-overlay {
    width: 100%;
    height: 100%;
    left: 0;
    background-color: #fff;
    transition: 0.3s all;
    display: grid;
    grid-template-columns: 5.25rem 14% 1fr 3.75rem;
    grid-template-rows: 4.6875rem;
    position: absolute;
    &.reduce-width {
      left: 8% !important;
    }
    .select-grid-item {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      .check-box {
        display: flex;
        align-items: center;
        transform: scale(0.81);
        cursor: pointer;
        color: #bdbdbd;
        .checked {
          color: cornflowerblue;
        }
        .disabled {
          cursor: not-allowed;
          color: #ccc;
        }
      }
      .open-actions {
        transition: opacity 0.35s;
        position: absolute;
        opacity: 0;
        top: 0;
        left: 10px;
        transform: scale(1.3);
        color: #bdbdbd;
        cursor: pointer;
        user-select: none;
        &:hover {
          opacity: 1;
        }
        &.rotate-opposite {
          transform: rotate(180deg) scale(1.3) !important;
        }
      }
    }
    .expand-grid-item {
      display: flex;
      align-items: center;
      justify-content: center;
      .expand-icon {
        cursor: pointer;
        user-select: none;
        color: #bdbdbd;
      }
    }
  }
}

.identity-grid-item {
  display: flex;
  flex-direction: column;
  padding: 0.625rem;
  justify-content: space-evenly;
  .expert-name {
    font-weight: 500;
    color: #404040;
    font-size: 1rem;
  }
  .source-search-code-row {
    display: flex;
    align-items: center;
    .source-pill {
      margin-right: 0.3125rem;
    }
    .search-id {
      color: #888;
      text-transform: uppercase;
      font-size: 0.7125rem;
      text-wrap: nowrap;
    }
  }
}
.details-grid-item {
  padding: 0.625rem;
  display: flex;
  .country {
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;
    width: 8.75rem;
    .country-label {
      position: absolute;
      font-size: 0.65rem;
      font-weight: 400;
      color: #a5a5a5;
      text-transform: uppercase;
      top: 1px;
    }
    .country-value {
      font-weight: 600;
    }
  }
  .email {
    display: flex;
    width: 100%;
    min-width: 15.625rem;
    height: 100%;
    position: relative;
    align-items: center;
    margin-right: 1.5625rem;
    .email-label {
      position: absolute;
      font-size: 0.65rem;
      font-weight: 400;
      color: #a5a5a5;
      text-transform: uppercase;
      top: 1px;
    }
    .ellipse-box {
      width: 1.5625rem !important;
      display: flex;
      background-color: #cad0d9;
      color: white;
      align-items: center;
      justify-content: center;
      border-radius: 7px;
      font-size: 1rem;
      height: 1.125rem;
      position: relative;
      cursor: pointer;
      margin-left: 0.3125rem;
      user-select: none;
      &:hover {
        .drop-down {
          display: block;
        }
      }
      .drop-down {
        display: none;
        left: 0;
        top: 20px;
      }
    }
  }
  .availability {
    display: flex;
    width: 100%;
    height: 100%;
    position: relative;
    align-items: center;
    .availability-label {
      position: absolute;
      font-size: 0.65rem;
      font-weight: 400;
      color: #a5a5a5;
      text-transform: uppercase;
      top: 1px;
    }
    .availability-list {
      display: flex;
      align-items: center;
      .availability-pill {
        margin-right: 0.3125rem;
      }
      .ellipse-box {
        width: 1.5625rem !important;
        display: flex;
        background-color: #cad0d9;
        color: white;
        align-items: center;
        justify-content: center;
        border-radius: 7px;
        font-size: 1rem;
        height: 1.125rem;
        position: relative;
        cursor: pointer;
        user-select: none;
        &:hover {
          .drop-down {
            display: block;
          }
        }
        .drop-down {
          display: none;
          right: 0;
          top: 20px;
        }
      }
    }
  }
  .schedule-button {
    align-self: center;
    position: absolute;
    right: 66px;
  }
  .conference {
    display: flex;
    align-items: center;
    height: 4.0625rem;
    position: relative;
    top: -5px;
    width: max-content;
    background-color: #f7f8fa;
    font-size: 0.6875rem;
    line-height: normal;
    padding: 0 0.625rem;
    border-radius: 2px;
    mat-icon {
      margin-right: 0.3125rem;
      color: var(--muted);
      transform: scale(0.95) translate(-3px, 0);
    }
    .meta-info-vertical {
      height: 100%;
      .conference-title {
        font-weight: 400;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 15rem;
        overflow: hidden;
        height: 0.8125rem;
        display: initial;
      }
    }
    .edit {
      transform: scale(0.78);
      color: var(--muted);
      cursor: pointer;
    }
  }
}

.ghost-expert-details {
  width: 100%;
  left: 0;
  background-color: #fff;
  transition: 0.3s all;
  display: grid;
  grid-template-columns: 7.5rem 10% 1fr 10.625rem;
  height: 4.6875rem;
  margin-bottom: 0.625rem;
  box-shadow: var(--box-shadow-light);
  .phase-div {
    display: flex;
    align-items: center;
    justify-content: center;
    .phase-box {
      font-size: 0.75rem;
      width: 84%;
      display: -webkit-box;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: slateblue;
      color: white;
      border-radius: 2px;
      font-weight: 400;
    }
  }
  .go-to-expert-div {
    width: max-content;
    display: flex;
    align-items: center;
    justify-content: center;
    button {
      font-weight: 400;
    }
  }
}
