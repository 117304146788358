.ct-emailChip {
  background-color: #f2f2f2;
  border-radius: 20px;
  color: #404040;
  min-width: 10rem;
  width: 13.75rem;
  display: -webkit-box;
  display: flex;
  align-items: center;
  height: 1.25rem;
  padding: 0 0.625rem;
  cursor: copy;
  &--selected {
    border: 2px solid cornflowerblue;
  }
  &__text {
    font-size: var(--font-size-pill);
    width: 100%;
    padding: 0 1.4rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__icon {
    transform: scale(0.845);
    &--valid {
      color: #95d145;
    }
    &--pending {
      color: #f5c600;
    }
    &--invalid {
      color: #d62f2c;
      transform: scale(0.845) rotate(45deg);
    }
  }
  &__label {
    position: absolute;
    font-size: 0.65rem;
    font-weight: 400;
    color: #a5a5a5;
    text-transform: uppercase;
    top: -1px;
  }
}
